import { ApolloProvider } from '@apollo/client'
import { track } from '@dfds-frontend/tracking'
import { GooglePlacesAPIProvider } from '@dfds-ui/google-places'
import { GlobalStyles } from '@dfds-ui/react-components'
import { createTheme, ThemeProvider } from '@mui/material'
import { useEffect } from 'react'

import { DotComStyles } from '../src/themes'
import { client } from './../src/apollo/client'

if (!process.env.GATSBY_GOOGLE_API_KEY) {
  console.warn(
    `GATSBY_GOOGLE_API_KEY is not set, please make sure to set it in your env file!`,
  )
}

export const Provider = ({ children }) => {
  const language =
    typeof window !== 'undefined'
      ? window.location.pathname.split('/')[1].split('-').shift()
      : 'en'

  useEffect(() => {
    track({ event: 'originalLocation', originalLocation: window.location.href })
    if (
      typeof navigator !== 'undefined' &&
      (navigator.userAgent.includes('MSIE ') ||
        navigator.userAgent.includes('Trident/'))
    ) {
      track({ event: 'browserNotSupported' })
    }
  })

  return (
    <ApolloProvider client={client}>
      <GooglePlacesAPIProvider
        language={language}
        apiKey={process.env.GATSBY_GOOGLE_API_KEY}
        libraries={'geometry,drawing,places'}
      >
        {children}
      </GooglePlacesAPIProvider>
    </ApolloProvider>
  )
}

// BEGIN - Fix issue with MUI - complaining a component was not inside a ThemeProvider - https://mui.com/material-ui/migration/troubleshooting/#makestyles-typeerror-cannot-read-property-drawer-of-undefined
const theme = createTheme()
// END

export const wrapRootElement = ({ element }) => {
  return (
    <Provider>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <DotComStyles />
        <div id="chatbutton"></div>
        {element}
      </ThemeProvider>
    </Provider>
  )
}
