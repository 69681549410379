import { useCallback, useEffect, useState } from 'react'
import { usePageContext } from '../PageContext'
import { useCookieContext } from './../CookieConsent/context'

const COOKIE_CONSENT_GIVEN_EVENT = 'CassieSubmittedConsent'

const Segment = ({ enableSegment = true, writeKey }) => {
  const consent = useCookieContext()
  const [segmentIsLoading, setSegmentIsLoading] = useState(false)

  const loadSegment = useCallback(() => {
    const { fullConsent } = consent
    const { analytics, location } = window
    const analyticsInitialized = analytics.initialized
    if (
      enableSegment &&
      fullConsent &&
      !analyticsInitialized &&
      !segmentIsLoading
    ) {
      setSegmentIsLoading(true)
      analytics.load(writeKey)
      analytics.ready(() => setSegmentIsLoading(false))
      return
    }
    if (!fullConsent && analyticsInitialized) {
      location.reload()
    }
  }, [enableSegment, segmentIsLoading, writeKey, consent])

  const isBrowserAndAnalytics =
    typeof window !== 'undefined' && !!window?.analytics

  if (isBrowserAndAnalytics) loadSegment()

  useEffect(() => {
    if (isBrowserAndAnalytics)
      document.addEventListener(COOKIE_CONSENT_GIVEN_EVENT, loadSegment, {
        once: true,
      })
    return () =>
      document.removeEventListener(COOKIE_CONSENT_GIVEN_EVENT, loadSegment)
  }, [isBrowserAndAnalytics, loadSegment])

  return null
}

const SegmentLoader = ({ enableSegment }) => {
  const businessUnit = usePageContext()?.businessUnit?.toLowerCase()
  const key = process.env.GATSBY_SEGMENT_API_KEY
  const segmentCanLoad = businessUnit !== 'passenger' || enableSegment

  // Segment can only be loaded in productions on B2B and group pages as well as PAXOB.
  return <Segment writeKey={key} enableSegment={segmentCanLoad} />
}

export default SegmentLoader
