import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { createClient } from 'contentful'
import fetch from 'isomorphic-fetch'
import introspection from '../__generated__/fragment-matcher'
import resolvers from './resolvers'
import typeDefs from './typeDefs'

export const previewClient = new ApolloClient({
  link: createHttpLink({
    uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.GATSBY_CONTENTFUL_SPACE}/environments/${process.env.GATSBY_CONTENTFUL_ENV}`,
    headers: {
      authorization: `Bearer ${process.env.GATSBY_CONTENTFUL_PREVIEW_ACCESS_TOKEN}`,
    },
    fetch,
  }),
  cache: new InMemoryCache({
    possibleTypes: introspection.possibleTypes,
    addTypename: false,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'none',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'none',
    },
  },
  resolvers,
  typeDefs,
})

export const client = new ApolloClient({
  link: createHttpLink({
    uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.GATSBY_CONTENTFUL_SPACE}/environments/${process.env.GATSBY_CONTENTFUL_ENV}`,
    headers: {
      authorization: `Bearer ${process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN}`,
    },
    fetch,
  }),
  cache: new InMemoryCache({
    possibleTypes: introspection.possibleTypes,
  }),
  resolvers,
  typeDefs,
})

export const createFormsClient = (env = 'dev') =>
  new ApolloClient({
    link: createHttpLink({
      uri: `https://graphql.contentful.com/content/v1/spaces/z860498abl84/environments/${env}?access_token=cf3eb41c6603c064c84111798dd42d288c8e0262017997f894653da20797e665`,
    }),
    cache: new InMemoryCache({
      possibleTypes: [
        {
          kind: 'OBJECT',
          name: 'Query',
          possibleTypes: null,
        },
        {
          kind: 'SCALAR',
          name: 'String',
          possibleTypes: null,
        },
        {
          kind: 'SCALAR',
          name: 'Boolean',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'Asset',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'Sys',
          possibleTypes: null,
        },
        {
          kind: 'SCALAR',
          name: 'DateTime',
          possibleTypes: null,
        },
        {
          kind: 'SCALAR',
          name: 'Int',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'ImageTransformOptions',
          possibleTypes: null,
        },
        {
          kind: 'SCALAR',
          name: 'Dimension',
          possibleTypes: null,
        },
        {
          kind: 'SCALAR',
          name: 'Quality',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'ImageResizeStrategy',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'ImageResizeFocus',
          possibleTypes: null,
        },
        {
          kind: 'SCALAR',
          name: 'HexColor',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'ImageFormat',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'AssetLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'EntryCollection',
          possibleTypes: null,
        },
        {
          kind: 'INTERFACE',
          name: 'Entry',
          possibleTypes: [
            {
              name: 'TitleAndMedia',
            },
            {
              name: 'ArrayOfItems',
            },
            {
              name: 'TeamName',
            },
            {
              name: 'ReleaseNotes',
            },
            {
              name: 'SimpleString',
            },
            {
              name: 'GenericPort',
            },
            {
              name: 'GenericRoute',
            },
            {
              name: 'LongString',
            },
            {
              name: 'TitleAndLink',
            },
            {
              name: 'RichTextString',
            },
            {
              name: 'NamespaceAndString',
            },
            {
              name: 'PluralString',
            },
            {
              name: 'FormCheckboxField',
            },
            {
              name: 'Form',
            },
            {
              name: 'FormInputField',
            },
            {
              name: 'FormSelectField',
            },
            {
              name: 'FormSelectOption',
            },
            {
              name: 'FormMessage',
            },
            {
              name: 'ConsentText',
            },
            {
              name: 'FormAddressSelect',
            },
            {
              name: 'ConsentPoc',
            },
            {
              name: 'MigrationLog',
            },
            {
              name: 'DfdsAppSimpleString',
            },
          ],
        },
        {
          kind: 'OBJECT',
          name: 'TitleAndMediaCollection',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'TitleAndMedia',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'TitleAndMediaLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'ArrayOfItemsCollection',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'ArrayOfItems',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'ArrayOfItemsLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'TeamName',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'TeamNameLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'ReleaseNotesCollection',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'ReleaseNotes',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'ReleaseNotesLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'SimpleStringCollection',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'SimpleString',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'SimpleStringLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'GenericPortCollection',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'GenericPort',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'GenericPortLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'GenericRouteCollection',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'GenericRoute',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'GenericRouteLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'LongStringCollection',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'LongString',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'LongStringLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'TitleAndLinkCollection',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'TitleAndLink',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'TitleAndLinkLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'RichTextStringCollection',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'RichTextString',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'RichTextStringLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'RichTextStringRichText',
          possibleTypes: null,
        },
        {
          kind: 'SCALAR',
          name: 'JSON',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'RichTextStringRichTextLinks',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'RichTextStringRichTextEntries',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'RichTextStringRichTextAssets',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'NamespaceAndStringCollection',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'NamespaceAndString',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'NamespaceAndStringLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'ArrayOfItemsArrayOfRefsCollection',
          possibleTypes: null,
        },
        {
          kind: 'UNION',
          name: 'ArrayOfItemsArrayOfRefsItem',
          possibleTypes: [
            {
              name: 'LongString',
            },
            {
              name: 'NamespaceAndString',
            },
            {
              name: 'PluralString',
            },
            {
              name: 'RichTextString',
            },
            {
              name: 'SimpleString',
            },
            {
              name: 'TitleAndLink',
            },
            {
              name: 'TitleAndMedia',
            },
          ],
        },
        {
          kind: 'OBJECT',
          name: 'PluralString',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'PluralStringLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'AssetFilter',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'SysFilter',
          possibleTypes: null,
        },
        {
          kind: 'SCALAR',
          name: 'Float',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'AssetOrder',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'AssetCollection',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormCheckboxField',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormCheckboxFieldLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormCollection',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'Form',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormFieldsCollection',
          possibleTypes: null,
        },
        {
          kind: 'UNION',
          name: 'FormFieldsItem',
          possibleTypes: [
            {
              name: 'FormCheckboxField',
            },
            {
              name: 'FormInputField',
            },
            {
              name: 'FormSelectField',
            },
          ],
        },
        {
          kind: 'OBJECT',
          name: 'FormInputField',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormInputFieldLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormSelectField',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormSelectFieldLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormSelectFieldOptionsCollection',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormSelectOption',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormSelectOptionLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormSelectFieldCollection',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormMessage',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormMessageLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'ConsentText',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'ConsentTextLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormCheckboxFieldCollection',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'FormCheckboxFieldFilter',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'cfConsentTextNestedFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'FormCheckboxFieldOrder',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'FormFilter',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'cfFormMessageNestedFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'FormOrder',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'FormMessageFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'FormMessageOrder',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormMessageCollection',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormAddressSelect',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormAddressSelectLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'FormAddressSelectFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'FormAddressSelectOrder',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormAddressSelectCollection',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'ConsentPoc',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'ConsentPocLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'ConsentPocCheckboxLabel',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'ConsentPocCheckboxLabelLinks',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'ConsentPocCheckboxLabelEntries',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'ConsentPocCheckboxLabelAssets',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'ConsentPocFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'ConsentPocOrder',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'ConsentPocCollection',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'ConsentTextFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'ConsentTextOrder',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'ConsentTextCollection',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'FormSelectOptionFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'FormSelectOptionOrder',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormSelectOptionCollection',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'FormInputFieldFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'FormInputFieldOrder',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FormInputFieldCollection',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'FormSelectFieldFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'FormSelectFieldOrder',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'MigrationLog',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'MigrationLogLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'MigrationLogFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'MigrationLogOrder',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'MigrationLogCollection',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'ReleaseNotesFilter',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'cfTeamNameNestedFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'ReleaseNotesOrder',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'SimpleStringFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'SimpleStringOrder',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'LongStringFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'LongStringOrder',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'GenericPortFilter',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'cfSimpleStringNestedFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'GenericPortOrder',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'ArrayOfItemsFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'ArrayOfItemsOrder',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'TitleAndMediaFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'TitleAndMediaOrder',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'TeamNameFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'TeamNameOrder',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'TeamNameCollection',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'TitleAndLinkFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'TitleAndLinkOrder',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'RichTextStringFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'RichTextStringOrder',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'NamespaceAndStringFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'NamespaceAndStringOrder',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'GenericRouteFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'GenericRouteOrder',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'DfdsAppSimpleString',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'DfdsAppSimpleStringLinkingCollections',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'DfdsAppSimpleStringFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'DfdsAppSimpleStringOrder',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'DfdsAppSimpleStringCollection',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'PluralStringFilter',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'PluralStringOrder',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'PluralStringCollection',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: '__Schema',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: '__Type',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: '__TypeKind',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: '__Field',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: '__InputValue',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: '__EnumValue',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: '__Directive',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: '__DirectiveLocation',
          possibleTypes: null,
        },
      ],
    }),
  })

export const sharedContentClient = new ApolloClient({
  link: createHttpLink({
    fetch,
    uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.GATSBY_CONTENTFUL_T_SPACE}/environments/${process.env.GATSBY_CONTENTFUL_T_ENV}/`,
    headers: {
      authorization: `Bearer ${process.env.GATSBY_CONTENTFUL_T_ACCESS_TOKEN}`,
    },
  }),
  cache: new InMemoryCache({ resultCaching: false }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'none',
    },
  },
})

export const sharedContentClientRest = createClient({
  space: process.env.GATSBY_CONTENTFUL_T_SPACE,
  accessToken: process.env.GATSBY_CONTENTFUL_T_ACCESS_TOKEN,
  environment: process.env.GATSBY_CONTENTFUL_T_ENV,
})
