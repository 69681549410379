import getFaviconsBaseDir from './getFaviconsBaseDir'

export function getFaviconLinks(locale) {
  const faviconsBaseDir = getFaviconsBaseDir(locale)

  return [
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: `${faviconsBaseDir}/favicons/apple-touch-icon.png`,
    },
    {
      rel: 'icon',
      sizes: '192x192',
      href: `${faviconsBaseDir}/favicons/android-chrome-192x192.png`,
    },
    {
      rel: 'icon',
      sizes: '512x512',
      href: `${faviconsBaseDir}/favicons/android-chrome-512x512.png`,
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: `${faviconsBaseDir}/favicons/favicon-32x32.png`,
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: `${faviconsBaseDir}/favicons/favicon-16x16.png`,
    },
    {
      rel: 'mask-icon',
      href: `${faviconsBaseDir}/favicons/safari-pinned-tab.svg`,
      color: '#5bbad5',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: `${faviconsBaseDir}/favicons/favicon.ico`,
    },
  ]
}

export function getFaviconMetaTags(locale) {
  const faviconsBaseDir = getFaviconsBaseDir(locale)

  return [
    {
      name: 'msapplication-config',
      content: `${faviconsBaseDir}/favicons/browserconfig.xml`,
    },
  ]
}
