import {
  buildASTSchema,
  isListType,
  isNonNullType,
  isScalarType,
  isObjectType,
} from 'graphql'
import typeDefs from './typeDefs'

/**
 * Based on the GraphQLOutput type (of a field) we apply proper `__typename`
 * @param {import('graphql').GraphQLOutputType} type
 * @param {*} data
 */
function addTypename(type, data) {
  // If it's a scalar then it's not an object so it doesn't have __typename
  if (isScalarType(type)) {
    return typeof data === 'undefined' ? null : data
  }

  if (typeof data === 'undefined' || data === null) {
    return
  }

  // If it's a list, iterate over each element
  if (isListType(type)) {
    return data.map((item) => addTypename(type.ofType, item))
  }

  // If it's NonNull then go deeper and pass the type
  if (isNonNullType(type)) {
    return addTypename(type.ofType, data)
  }

  // If it's an object, then it has to have __typename
  if (isObjectType(type)) {
    const fieldMap = type.getFields()

    const item = {
      __typename: type.name,
    }

    // each object has fields so we need to iterate over all of them
    for (const fieldName in fieldMap) {
      const field = fieldMap[fieldName]

      item[fieldName] = addTypename(field.type, data[fieldName])
    }

    return item
  }

  // This should not happen until we add unions and stuff
  console.warn(`GraphQL Transform does not support:`, {
    type,
    data,
  })
}

/**
 * Adds `__typename` to resolved objects.
 * It's necessary because how Apollo Cache works and because the returned data has no `__typename` fields
 *
 * @param {{[type: string]: { [field:string]: Function }}} resolverMap
 */
export function transform(resolverMap) {
  // first, we need a schema
  const schema = buildASTSchema(typeDefs)
  const resolvers = {}

  /**
   * @param {import('graphql').GraphQLField} field
   * @param {Function} resolver
   */
  function correctResolver(field, resolver) {
    return async function fixedResolver(obj, args, ctx, info) {
      const data = await resolver(obj, args, ctx, info)
      return addTypename(field.type, data)
    }
  }

  // iterate over each defined type (Query, Mutation, Subscription)
  for (const typeName in resolverMap) {
    const typeResolvers = resolverMap[typeName]
    // get the type from schema so we can understand what fields it has
    const type = schema.getType(typeName)

    resolvers[typeName] = {}

    // iterate over fields
    for (const fieldName in typeResolvers) {
      // now we need to understand what is the return type of that field
      const field = type.getFields()[fieldName]
      // with all that knowledge we fix the resolver
      resolvers[typeName][fieldName] = correctResolver(
        field,
        resolverMap[typeName][fieldName],
      )
    }
  }

  return resolvers
}
