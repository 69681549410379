export function isBookingFlowPage(slugId, businessUnit) {
  const result =
    slugId &&
    businessUnit === 'PASSENGER' &&
    (slugId === '7grkFo3cERWIQSnunnJabt' || // passenger-ferries/yourbooking
      slugId === '3stjumkQ2dMMBMl4ArMSQa' || // passenger-ferries/checkout
      slugId === '2GU9pkOuoAVhUNx6lmbbJG' || // passenger-ferries/confirmation
      slugId === '5D9v44YRZhp5ErKZ9bs2lE') // passenger-ferries/pending
  return result
}
