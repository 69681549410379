import qs from 'query-string'

const availableRoutes = async (_, args) => {
  const data = await fetch(
    `${process.env.GATSBY_BFF_URL}availableroutes?${qs.stringify(args)}`,
  )
  const { routes } = await data.json()
  return routes
}

export default availableRoutes
