import qs from 'query-string'

const EUROLANDIR_URL = 'https://tools.eurolandir.com/tools/services/'

function sanitizeNode(node) {
  if (!node.removeAttribute) {
    return
  }
  ;['width', 'style', 'class', 'id'].forEach((prop) =>
    node.removeAttribute(prop),
  )
}

function sanitizeNodeTree(node) {
  sanitizeNode(node)
  if (!node.hasChildNodes()) {
    return
  }
  const defaultLength = node.childNodes.length
  for (let i = 0, length = defaultLength; i < length; i++) {
    const element = node.childNodes[i]
    sanitizeNodeTree(element)
  }
  return node
}

export const nasdaq = async (_, { startDate, endDate, locale }) => {
  const data = await (
    await fetch(
      `${EUROLANDIR_URL}?${qs.stringify({
        func: 'GetCompanyPressReleasesByDateRange',
        companycode: 'dk-dfds',
        format: 'json',
        startdate: startDate,
        enddate: endDate,
        lang: locale,
        ...(locale === 'Danish' && { v: 'dk' }),
      })}`,
    )
  ).json()

  const filteredData = data.filter(
    (entry) => entry.messagetype !== 'Non-Regulatory',
  )
  return filteredData
}

export const nasdaqById = async (_, { id, locale }) => {
  const data = await (
    await fetch(
      `${EUROLANDIR_URL}?${qs.stringify({
        func: 'GetPressReleaseById',
        companycode: 'dk-dfds',
        format: 'json',
        id,
        lang: locale,
      })}`,
    )
  ).json()

  const body = new DOMParser()
    .parseFromString(data[0].message, 'text/html')
    .querySelector('body')

  return {
    ...data[0],
    message: sanitizeNodeTree(body).innerHTML,
    date: data[0].date,
  }
}
