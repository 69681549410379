import { usePageContext } from '../components/PageContext'
import { useLocaleContext } from '../components/LocaleContext'
import { useLocationProvider } from '../components/LocationProvider/LocationProvider'

const usePageTrackingData = () => {
  const {
    slugId,
    pageTagging,
    pageTitle,
    statusCode,
    businessUnit,
    explicitTracking,
  } = usePageContext()
  const {
    locale,
    country: countryCode,
    language: languageCode,
  } = useLocaleContext()
  const { origin, pathname } = useLocationProvider()

  const pageRouteName =
    (pageTagging?.routeCode && pageTagging?.routeCode.split('.')[0]) ??
    undefined
  const pageRouteCode =
    (pageTagging?.routeCode && pageTagging?.routeCode.split('.')[1]) ??
    undefined
  const pageProductType = pageTagging?.product ?? undefined
  const pageType = pageTagging?.pageType ?? undefined

  return {
    explicitTracking,
    getPageTrackingData: () => {
      return {
        bookingEngine: 'dotcom',
        contentfulId: slugId,
        pagePath: pathname,
        businessArea: businessUnit,
        pageTitle,
        statusCode,
        currentLocale: locale,
        countryCode,
        languageCode,
        url: `${origin}${pathname}`,
        pageRouteCode: pageRouteCode,
        pageRouteName: pageRouteName,
        pageProductType: pageProductType,
        pageType: pageType,
      }
    },
  }
}

export default usePageTrackingData
