import qs from 'query-string'

const departures = async (_, args) => {
  const data = await fetch(
    `${process.env.GATSBY_BFF_URL}departures?${qs.stringify(args)}`,
  )
  const { departures } = await data.json()
  return departures
}

export default departures
