const createMicrodataOrganization = (microdata) => {
  return {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: microdata.name,
    logo: microdata.logo.url.startsWith('https://')
      ? microdata.logo.url
      : `https://${microdata.logo.url}`,
    url: microdata.url,
    sameAs: microdata.sameAs,
    founder: {
      '@type': 'Person',
      name: microdata.founderName,
    },
    foundingDate: microdata.foundingDate,
    contactPoint: {
      '@type': 'contactPoint',
      telephone: microdata.contactTelephone,
      contactType: microdata.contactType,
      areaServed: microdata.areaServed,
    },
  }
}

export default createMicrodataOrganization
