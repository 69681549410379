const buildUrl = (locale, slug) => {
  return `/${locale.toLowerCase() + ensureAbsoluteSlug(slug)}`
}

const ensureAbsoluteSlug = (slug) => {
  if (!slug || slug === '/') {
    return ''
  }
  const absoliuteSlug = slug.endsWith('/') ? slug.slice(0, -1) : slug
  return absoliuteSlug.startsWith('/') ? absoliuteSlug : `/${absoliuteSlug}`
}

export default buildUrl
