const vehicleTypes = async (_, args) => {
  const data = await fetch(`${process.env.GATSBY_BFF_URL}vehicleTypes`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(args),
  })
  const response = await data.json()
  return response
}

export default vehicleTypes
