import React from 'react'

const AppShellContext = React.createContext(undefined)

export const useAppShellContext = () => {
  const context = React.useContext(AppShellContext)
  if (context === undefined) {
    throw new Error('useAppShellContext must be used within a AppShellProvider')
  }
  return context
}

export const AppShellProvider = ({ children, staticValue }) => {
  const [value, setValue] = React.useState(staticValue)
  return (
    <AppShellContext.Provider
      value={{ ...value, setAppShellContext: setValue }}
    >
      {children}
    </AppShellContext.Provider>
  )
}
