import { useEffect } from 'react'
import { useLocaleContext } from '../LocaleContext'

const getVercelHost = () => {
  const { host } = window.location
  if (host === 'www.dfds.com') return 'https://azure-login.dfds-pax-web.com'
  else if (host === 'www.accept.dfds.com')
    return 'https://azure-login-uat.dfds-pax-web.com'
  else if (host === 'www.test.dfds.com')
    return 'https://azure-login-test.dfds-pax-web.com'
  else return 'https://azure-login-dev.dfds-pax-web.com'
}

const loadFromVercelAndInjectScript = async () => {
  const host = getVercelHost()
  const path = `${host}/.vite/manifest.json`
  const response = await fetch(path)
  const manifest = await response.json()
  await new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = `${host}/${manifest['index.html']['file']}`
    script.type = 'module'
    script.id = 'azure-login-script'
    script.onload = resolve
    document.querySelector('body')?.appendChild(script)
    const stylesheet = document.createElement('link')
    stylesheet.rel = 'stylesheet'
    stylesheet.href = `${host}/${manifest['index.html']['css'][0]}`
    document.querySelector('head')?.appendChild(stylesheet)
  })
  let azureLoginLoaded = document.azureLoginCreate() !== null
  while (!azureLoginLoaded) {
    await new Promise((resolve) => setTimeout(resolve, 500))
    azureLoginLoaded = document.azureLoginCreate() !== null
  }
}

export const Login = () => {
  const { localeLower } = useLocaleContext()

  useEffect(() => {
    ;(async () => {
      if (
        typeof window !== 'undefined' &&
        window.location.host.includes('dfds.com') &&
        !window.location.host.includes('dotcom-pr')
      ) {
        const azureLoginScriptLoaded =
          document.getElementById('azure-login-script') !== null
        if (!azureLoginScriptLoaded) {
          await loadFromVercelAndInjectScript()
        }

        const azureLoginLoaded = document.getElementById('azure-login') !== null
        if (!azureLoginLoaded) {
          document.azureLoginCreate()
        }
      }
    })()
  }, [localeLower])

  return <div id="azure-login-root" tabIndex={0}></div>
}
