import { track } from '@dfds-frontend/tracking'
import { ChevronRight } from '@dfds-ui/icons/system'
import { Button } from '@dfds-ui/react-components'
import { theme } from '@dfds-ui/theme'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'

import { usePageTrackingData } from '../../tracking'
import AutoLink from './AutoLink'

const StyledLink = styled(AutoLink)`
  display: flex;
  align-items: ${(props) => (props.iconatstart ? 'flex-start' : 'center')};
`

const LinkText = styled.span`
  text-transform: ${(props) => props.textTransform};
  text-align: ${(props) => props.textAlign};
  font-weight: ${(props) => props.fontWeight};
  order: ${(props) => (props.iconatstart ? 2 : 1)};
  color: ${(props) => props.backgroundcolor && theme.colors.text.light.primary};
`

const LinkIcon = styled.span`
  order: ${(props) => (props.iconatstart ? 1 : 2)};
  margin: 0 ${(props) => (props.iconatstart ? '10px' : 0)} 0
    ${(props) => (props.iconatstart ? 0 : '10px')};
`

const LocalizedLink = (props) => {
  const {
    callToAction,
    destination,
    linkText,
    ctaButtonTracking,
    ctaButtonStage,
    ctaButtonName,
    iconatstart,
    fontWeight = 700,
    textTransform = 'uppercase',
    backgroundcolor,
    textAlign = 'right',
    url,
    ...rest
  } = props

  const { getLocalizedLinkTrackingData } = useLocalizedLinkTrackingData()

  if (!url && !destination) {
    return null
  }
  const trackingData = getLocalizedLinkTrackingData({
    linkText,
    destination,
    ctaButtonStage,
    ctaButtonName,
  })

  if (callToAction) {
    return (
      <Button
        as={AutoLink}
        destination={destination}
        url={url}
        onClick={() => ctaButtonTracking && track(trackingData)}
        css={css`
          color: ${theme.colors.text.light.primary} !important;
        `}
      >
        {linkText}
      </Button>
    )
  }
  return (
    <StyledLink
      iconatstart={iconatstart}
      destination={destination}
      url={url}
      onClick={() => ctaButtonTracking && track(trackingData)}
      {...rest}
    >
      <LinkText
        iconatstart={iconatstart}
        textTransform={textTransform}
        fontWeight={fontWeight}
        backgroundcolor={backgroundcolor}
        textAlign={textAlign}
      >
        {linkText}
      </LinkText>
      <LinkIcon>
        <ChevronRight
          width={'24px'}
          height={'24px'}
          color={theme.colors.text.secondary.primary}
        />
      </LinkIcon>
    </StyledLink>
  )
}

export const useLocalizedLinkTrackingData = () => {
  const { getPageTrackingData } = usePageTrackingData()
  const { bookingEngine, businessArea, contentfulId } = getPageTrackingData()

  const pageTrackingData = {
    bookingEngine,
    businessArea,
    contentfulId,
  }

  const getLocalizedLinkTrackingData = (localizedLink) => ({
    event: 'buttonClicked',
    ...pageTrackingData,
    ...(localizedLink?.destination?.slug
      ? { eventAction: `goto_${localizedLink.destination.slug}` }
      : {}),
    ...(localizedLink?.linkText ? { eventLabel: localizedLink.linkText } : {}),
    ...(localizedLink?.ctaButtonStage
      ? { stage: localizedLink.ctaButtonStage }
      : {}),
    ...(localizedLink?.ctaButtonName
      ? { cta_name: localizedLink.ctaButtonName }
      : {}),
  })

  return { getLocalizedLinkTrackingData }
}

// If the previous page change was triggered by a CTA button click then include extra data in VPV event
export const conditionallyAddCTAButtonDataToPageTrackingData = (
  pageTrackingData,
) => {
  const isBrowser = typeof window !== 'undefined'
  if (!isBrowser) return pageTrackingData

  const { dataLayer } = window
  // if there is a vpv in between this vpv and the last CTA click, then this vpv can not be due to CTA click
  let i = dataLayer.length
  let lastButtonClick
  while (i--) {
    const element = dataLayer[i]
    const { event } = element
    if (event === 'vpv') {
      return pageTrackingData
    } else if (event === 'buttonClicked') {
      lastButtonClick = element
      break
    }
  }

  if (!lastButtonClick) return pageTrackingData

  const { cta_name, stage: cta_stage, eventAction } = lastButtonClick

  // eventAction is prepended with 'goto_'
  const cleanedEventAction = eventAction?.substring(5)

  const ctaEventMatchesPagePath =
    pageTrackingData?.pagePath?.includes(cleanedEventAction)

  if (!ctaEventMatchesPagePath) return pageTrackingData

  return { cta_name, cta_stage, ...pageTrackingData }
}

export const linkFragment = graphql`
  fragment Link on contentful_Link {
    __typename
    sys {
      id
    }
    linkText
    ctaButtonTracking
    ctaButtonName
    ctaButtonStage
    callToAction
    description
    destination {
      ...UrlSlug
    }
  }

  fragment AppLink on contentful_AppLink {
    __typename
    sys {
      id
    }
    linkText
    callToAction
    description
    url
  }

  fragment UrlSlug on contentful_UrlSlug {
    __typename
    sys {
      id
    }
    slug
    disableIndexing
    theme
  }
`

export default LocalizedLink
