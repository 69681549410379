export const BusinessUnitLandingPageList = {
  items: [
    {
      locale: 'en',
      freightShippingSectionSlug: 'freight-shipping',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'logistics-solutions',
      passengerFerriesSectionSlug: 'passenger-ferries',
    },
    {
      locale: 'sv-SE',
      freightShippingSectionSlug: 'sjofrakt',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'logistiklosningar',
      passengerFerriesSectionSlug: 'passagerarfartyg',
    },
    {
      locale: 'et-EE',
      freightShippingSectionSlug: 'kaubavedu',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'logistikalahendused',
      passengerFerriesSectionSlug: 'reisilaevad',
    },
    {
      locale: 'nl-BE',
      freightShippingSectionSlug: 'vrachtverscheping',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'logistieke-oplossingen',
      passengerFerriesSectionSlug: 'overtochten-passagiers',
    },
    {
      locale: 'fr-BE',
      freightShippingSectionSlug: 'transport-de-fret-maritime',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'solutions-logistiques',
      passengerFerriesSectionSlug: 'ferries-passagers',
    },
    {
      locale: 'da-DK',
      freightShippingSectionSlug: 'shipping',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'logistikloesninger',
      passengerFerriesSectionSlug: 'passagerfaerger',
    },
    {
      locale: 'it-IT',
      freightShippingSectionSlug: 'spedizione-merci',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'soluzioni-logistiche',
      passengerFerriesSectionSlug: 'traghetti-passeggeri',
    },
    {
      locale: 'es-ES',
      freightShippingSectionSlug: 'transporte-maritimo',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'soluciones-logisticas',
      passengerFerriesSectionSlug: 'ferris-de-pasajeros',
    },
    {
      locale: 'pl-PL',
      freightShippingSectionSlug: 'transport-morski',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'rozwiazania-logistyczne',
      passengerFerriesSectionSlug: 'promy-pasazerskie',
    },
    {
      locale: 'ru-RU',
      freightShippingSectionSlug: 'morskiye-perevozki',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'logisticheskiye-resheniya',
      passengerFerriesSectionSlug: 'passazhirskiye-paromy',
    },
    {
      locale: 'nb-NO',
      freightShippingSectionSlug: 'sjoefrakt',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'logistikkloesninger',
      passengerFerriesSectionSlug: 'ferge',
    },
    {
      locale: 'lt-LT',
      freightShippingSectionSlug: 'kroviniu-gabenimas',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'logistikos-sprendimai',
      passengerFerriesSectionSlug: 'keleiviniai-keltai',
    },
    {
      locale: 'de-DE',
      freightShippingSectionSlug: 'frachtschifffahrt',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'logistikloesungen',
      passengerFerriesSectionSlug: 'passagierfaehren',
    },
    {
      locale: 'lv-LV',
      freightShippingSectionSlug: 'kravu-parvadajumi',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'logistikas-risinajumi',
      passengerFerriesSectionSlug: 'pasazieru-pramji',
    },
    {
      locale: 'fr-FR',
      freightShippingSectionSlug: 'transport-de-fret-maritime',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'solutions-logistiques',
      passengerFerriesSectionSlug: 'ferries-passagers',
    },
    {
      locale: 'fr-MA',
      freightShippingSectionSlug: 'transport-de-fret-maritime',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'solutions-logistiques',
      passengerFerriesSectionSlug: 'ferries-passagers',
    },
    {
      locale: 'fi-FI',
      freightShippingSectionSlug: 'rahtialusliikenne',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'logistiikkaratkaisut',
      passengerFerriesSectionSlug: 'matkustajalautat',
    },
    {
      locale: 'nl-NL',
      freightShippingSectionSlug: 'vrachtverscheping',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'logistieke-oplossingen',
      passengerFerriesSectionSlug: 'overtochten-passagiers',
    },
    {
      locale: 'en-GB',
      freightShippingSectionSlug: 'freight-shipping',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'logistics-solutions',
      passengerFerriesSectionSlug: 'passenger-ferries',
    },
    {
      locale: 'ro-RO',
      freightShippingSectionSlug: 'expediere-marfa/serviciu-indisponibil',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'solutii-logistice/serviciu-indisponibil',
      passengerFerriesSectionSlug: 'feribot',
    },
    {
      locale: 'pt-BR',
      freightShippingSectionSlug: 'transporte-maritimo/servico-indisponivel',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'solucoes-logisticas/servico-indisponivel',
      passengerFerriesSectionSlug: 'balsas-de-passageiros',
    },
    {
      locale: 'bg-BG',
      freightShippingSectionSlug: 'tovarni-prevozi/uslugata-ne-e-dostupna',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug:
        'logistichni-resheniya/uslugata-ne-e-dostupna',
      passengerFerriesSectionSlug: 'patnicheski-feriboti',
    },
    {
      locale: 'cs-CZ',
      freightShippingSectionSlug:
        'nakladni-vodni-doprava/sluzba-neni-k-dispozici',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'logistika-reseni/sluzba-neni-k-dispozici',
      passengerFerriesSectionSlug: 'cestovni-trajekty',
    },
    {
      locale: 'sk-SK',
      freightShippingSectionSlug: 'nakladna-preprava/sluzba-nedostupna',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'logisticke-riesenia/sluzba-nedostupna',
      passengerFerriesSectionSlug: 'cestovne-trajekty',
    },
    {
      locale: 'zh-CN',
      freightShippingSectionSlug: 'freight-shipping/service-unavailable',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'logistics-solutions/service-unavailable',
      passengerFerriesSectionSlug: 'passenger-ferries',
    },
    {
      locale: 'ja-JP',
      freightShippingSectionSlug: 'freight-shipping/service-unavailable',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'logistics-solutions/service-unavailable',
      passengerFerriesSectionSlug: 'passenger-ferries',
    },
    {
      locale: 'ko-KR',
      freightShippingSectionSlug: 'freight-shipping/service-unavailable',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'logistics-solutions/service-unavailable',
      passengerFerriesSectionSlug: 'passenger-ferries',
    },
    {
      locale: 'tr-TR',
      freightShippingSectionSlug: 'yuk-tasimaciligi',
      groupSectionSlug: 'about',
      logisticsSolutionsSectionSlug: 'lojistik-cozumleri',
      passengerFerriesSectionSlug: 'yolcu-feribotlari',
    },
  ],
}
