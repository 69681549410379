import Cookies from 'js-cookie'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

const CASSIE_SUBMITTED_CONSENT_EVENT = 'CassieSubmittedConsent'
const CASSIE_TRUE_VALUE = 'True'
const CASSIE_NECESSARY = 'Necessary'
const CASSIE_STATISTICAL = 'Statistical'
const CASSIE_MARKETING = 'Marketing'
const CASSIE_FUNCTIONAL = 'Functional'
const FULL_CONSENT = 'fullConsent'

const cassieCookieKeys = Object.freeze([
  CASSIE_NECESSARY,
  CASSIE_STATISTICAL,
  CASSIE_MARKETING,
  CASSIE_FUNCTIONAL,
])

export const CookieContext = createContext()
CookieContext.displayName = 'Cookie Consent Context'

export const useCookieContext = () => useContext(CookieContext)

export const getCassieConsent = (cat) => Cookies.get(cat) === CASSIE_TRUE_VALUE

const getCookieConsent = () => {
  const consent = {}
  for (const cat of cassieCookieKeys)
    consent[cat.toLowerCase()] = getCassieConsent(cat)
  return {
    ...consent,
    [FULL_CONSENT]: Object.values(consent).every(Boolean), // checks all vals are 'true'
  }
}

export const CookieConsentProvider = ({ children }) => {
  const [cookieConsent, setCookieConsent] = useState(getCookieConsent())

  const cassieConsentHandler = useCallback(() => {
    setCookieConsent(getCookieConsent())
  }, [setCookieConsent])

  useEffect(() => {
    document.addEventListener(
      CASSIE_SUBMITTED_CONSENT_EVENT,
      cassieConsentHandler,
      { once: true },
    )
    return () => {
      document.removeEventListener(
        CASSIE_SUBMITTED_CONSENT_EVENT,
        cassieConsentHandler,
      )
    }
  }, [cassieConsentHandler])

  return (
    <CookieContext.Provider value={cookieConsent}>
      {children}
    </CookieContext.Provider>
  )
}
