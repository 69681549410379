import { wrapPageElementBase } from './gatsby/wrapPageElement'
import { wrapRootElement as universalWrapRootElement } from './gatsby/wrapRootElement'
import Cookies from 'js-cookie'
import { BusinessUnitLandingPageList } from './src/components/SiteSettings/BusinessUnitLandingPageList'

const onPreRouteUpdate = async ({ location, loadPage, prevLocation }) => {
  if (prevLocation) window.originalPath404 = window.location.pathname

  const { locale = 'en', businessUnit } = (await loadPage(location.pathname))
    .json.pageContext
  const {
    freightShippingSectionSlug,
    groupSectionSlug,
    logisticsSolutionsSectionSlug,
    passengerFerriesSectionSlug,
  } = BusinessUnitLandingPageList.items.find((bu) => bu.locale === locale)
  const getBuSlug = () => {
    if (businessUnit === 'PASSENGER') {
      return passengerFerriesSectionSlug
    } else if (businessUnit === 'LOGISTICS') {
      return logisticsSolutionsSectionSlug
    } else if (businessUnit === 'FREIGHT') {
      return freightShippingSectionSlug
    } else if (businessUnit === 'GROUP') {
      return groupSectionSlug
    }
  }
  Cookies.set('businessUnit', getBuSlug())
}

function wrapRootElement(props) {
  return universalWrapRootElement(props)
}

const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation?.pathname !== location.pathname) {
    Cookies.set('locale', location.pathname.split('/')[1])
  }

  window.locations ||= [document.referrer]
  window.locations.push(window.location.href)
  if (window.locations.length === 3) {
    window.locations.shift()
  }
  const documentReferrer = window.locations[0]
  Object.defineProperty(document, 'referrer', {
    configurable: true,
    get: () => documentReferrer,
  })
}

const onClientEntry = async () => {
  window.originalPath404 = window.location.pathname
  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }
}

const readSyncXhrResources = (locale) => {
  const request = new XMLHttpRequest()
  request.open('GET', `/locales/${locale}.json`, false) // use sync XHR because we want to stop hydration until we have resources loaded. If doing async we will get a flash where keys are shown
  request.send()
  return JSON.parse(request.responseText)
}

const wrapPageElement = (props) =>
  wrapPageElementBase(props, readSyncXhrResources)

const shouldUpdateScroll = ({
  routerProps: {
    location: { state },
  },
}) => state?.scroll ?? true

export {
  wrapPageElement,
  wrapRootElement,
  onPreRouteUpdate,
  onRouteUpdate,
  onClientEntry,
  shouldUpdateScroll,
}
