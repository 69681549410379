/**
 * This function defines a base filesystem path to find a correct variant of favicon depending on locale.
 *
 * @param {string} locale locale of the page.
 * @returns {string} A path to the folder with a favion.
 */

// These 2 locale require rendering of the same kind of favicon - without a cross
const noCrossedLocaleFavicon = ['fr-ma', 'es-es']

export default function getFaviconsBaseDir(locale) {
  return noCrossedLocaleFavicon.includes(locale.toLocaleLowerCase())
    ? '/locales/no-crossed'
    : '/locales/default'
}
