import { gql } from '@apollo/client'

const typeDefs = gql`
  type Query {
    departures(
      fromDate: String!
      toDate: String!
      portCode: String
      routeCode: String
      skip: Int
      take: Int
    ): [Departure!]!
    availableRoutes(salesOwner: Int!): [RouteId!]
    availableTravelDates(
      route: String!
      offerCode: String
      salesOwner: Int!
      noOfAdults: Int!
      childrenAges: [Int]
      fromDate: String!
      toDate: String!
      oneWayTrip: Boolean
    ): TravelDates
    availableInboundDates(
      route: String!
      offerCode: String
      salesOwner: Int!
      noOfAdults: Int!
      childrenAges: [Int]
      fromDate: String!
      outboundDate: String!
    ): TravelInboundDates
    validateOfferCode(offerCode: String!, locale: String!): OfferCode
    vehicleTypes(
      routeCode: String!
      salesOwner: Int!
      bookingCode: String!
    ): VehicleTypeResponse
    nasdaq(startDate: String!, endDate: String!, locale: String!): [Nasdaq]
    nasdaqById(id: String!, lang: String!): Nasdaq
    nasdaqChartLatest(companycode: String!): NasdaqChartLatest
    nasdaqChartHistory(companycode: String!, interval: Int!): NasdaqChartHistory
    nasdaqShareInfo(companycode: String!): NasdaqShareInfo
  }
  type VehicleType {
    code: String
    categoryCode: String
    height: String
    heightUnit: String
    length: String
    lengthUnit: String
    description: String
    maxNumberOfPassengers: Int
    petsAllowed: Boolean
    registrationNumberMandatory: Boolean
    availableTrailers: [Trailer]
  }
  type VehicleTypeResponse {
    defaultVehicleCode: String
    isPetsAllowedForFootPassenger: Boolean
    maxNumberOfPets: Int
    isVehicleMandatory: Boolean
    vehicleTypes: [VehicleType]
  }
  type Trailer {
    code: String
    description: String
    height: Int
    heightUnit: String
    length: Int
    lengthUnit: String
  }
  type OfferCode {
    offerCode: String
    locale: String
    productCode: String
    salesOwnerId: Int
    isValid: Boolean
  }
  type TravelDates {
    outboundDates: [TravelOffer]
    inboundDates: [TravelOffer]
  }
  type TravelInboundDates {
    inboundDates: [TravelOffer]
  }
  type TravelOffer {
    date: String
    isAvailableForOffer: Boolean
  }
  type Vacancy {
    vacancyId: String
    language: String
    companyName: String
    title: String
    activeFromDate: String
    activeToDate: String
    commence: String
    workplace: String
    deadline: String
    vacancyUrl: String
    country: String
    departmentName: String
  }
  type Departure {
    departureId: String
    scheduledDepartureTime: String
    scheduledArrivalTime: String
    route: Direction
  }
  type RouteId {
    name: String!
    code: String!
  }
  type Direction {
    departurePort: Port
    arrivalPort: Port
  }
  type Port {
    code: String
    name: String
    unlocode: String
    timezone: String
  }
  type Nasdaq {
    id: String
    date: String
    title: String
    link: String
    messagetype: String
    message: String
    attachments: [Attachment]
  }
  type NasdaqChartLatest {
    ISIN: String
    Name: String
    MarketName: String
    Currency: String
    Data: [NasdaqChartLatestData]
  }
  type NasdaqChartLatestData {
    DateTime: String
    Close: String
    Volume: String
  }
  type NasdaqChartHistory {
    ISIN: String
    Name: String
    MarketName: String
    Currency: String
    Data: [NasdaqChartHistoryData]
  }
  type NasdaqChartHistoryData {
    Date: String
    Open: String
    Volume: String
    High: String
    Low: String
  }
  type NasdaqShareInfo {
    Currency: String
    Bid: String
    Ask: String
    Change: String
    ChangePercent: String
    Last: String
    High: String
    Low: String
    Volume: String
    PreviousClose: String
    Date: String
    ISIN: String
    Symbol: String
    MarketName: String
    NoShares: String
    MarketCap: String
    Open: String
  }
  type Attachment {
    filename: String
    url: String
  }
`

export default typeDefs
