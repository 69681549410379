import { Script } from 'gatsby'
import { useLocaleContext } from '../LocaleContext'
import './cassie-overwrite.css'

const CookieBanner = () => {
  const { localeLower } = useLocaleContext()
  return (
    <Script id="CookieConsentConfig" type="text/javascript" async>
      {`
(function(){

var cassieLoaderId = "cassieLoaderId";
var existingCassieLoader = document.getElementById(cassieLoaderId);

if (existingCassieLoader) {
  return
}

var cassieLoaderScript = document.createElement('script')
var cdnUrl = 'https://cscript-cdn-irl'.concat(
  '${process.env.GATSBY_CASSIE_ENVIRONMENT}' === 'production' ? '' : '-uat',
  '.cassiecloud.com/loader.js'
)
cassieLoaderScript.src = cdnUrl
cassieLoaderScript.id = cassieLoaderId
document.head.appendChild(cassieLoaderScript)

var cassieSettings = {
  widgetProfileId: 1,
  languageCode: '${localeLower}',
  region: 'irl',
  licenseKey: '${process.env.GATSBY_CASSIE_LICENSEKEY}',
  environment: '${process.env.GATSBY_CASSIE_ENVIRONMENT}',
}
new Promise(function (resolve, reject) {
  cassieLoaderScript.onload = function () {
    if (typeof CassieWidgetLoaderModule !== 'undefined' && !window.CassieWidgetLoader) {

      window.CassieWidgetLoader = new CassieWidgetLoaderModule(cassieSettings)

      document.addEventListener('CassieTemplateInitialized', function () {
        addStrictlyNecessaryToggleSwitch()
        autoToggleBasedOnConsent()
        toggleWidgetWithParam()
      }, { once: true })

      function addStrictlyNecessaryToggleSwitch() {
        var strictlyNecessaryGroup = document.getElementById(
          'cassie_strictly_necessary'
        )
        var strictlyNecessaryGroupHeader = strictlyNecessaryGroup.querySelector(
          '.cassie-cookie-modal--group-head-container'
        )
        var strictlyNecessarySwitch = document.createElement('div')
        strictlyNecessarySwitch.classList.add(
          'cassie-toggle-switch',
          'cassie-cookie-group--toggle-switch'
        )
        strictlyNecessarySwitch.role = 'switch'
        strictlyNecessarySwitch.disabled = true
        strictlyNecessarySwitch.ariaDisabled = true
        strictlyNecessarySwitch.checked = true

        strictlyNecessaryGroupHeader.insertBefore(
          strictlyNecessarySwitch,
          strictlyNecessaryGroupHeader.children[1]
        )

        var strictlyNecessarySwitchSlider = document.createElement('span')
        strictlyNecessarySwitchSlider.classList.add(
          'cassie-toggle-switch--slider',
          'cassie-cookie-group--toggle-switch--slider',
          'cassie-toggle-switch--slider--active'
        )

        strictlyNecessarySwitch.appendChild(strictlyNecessarySwitchSlider)

        // prevent accept all from toggling the new toggle switch off
        var acceptAllToggleSwitch = document.getElementById(
          'cassie_accept_all_toggle_switch'
        )
        acceptAllToggleSwitch.addEventListener('click', function () {
          strictlyNecessarySwitchSlider.classList.add(
            'cassie-toggle-switch--slider--active'
          )
          strictlyNecessarySwitch.checked = true
        })
      }

      function autoToggleBasedOnConsent() {

        var _CassieWidgetLoader, _CassieWidgetLoader$W, _CassieWidgetLoader$W2;
        var hasConsent = (_CassieWidgetLoader = CassieWidgetLoader) === null || _CassieWidgetLoader === void 0 ? void 0 : (_CassieWidgetLoader$W = _CassieWidgetLoader.Widget) === null || _CassieWidgetLoader$W === void 0 ? void 0 : (_CassieWidgetLoader$W2 = _CassieWidgetLoader$W.widgetTemplate) === null || _CassieWidgetLoader$W2 === void 0 ? void 0 : _CassieWidgetLoader$W2.hasConsent;

        if (!hasConsent) {
          var allToggles = document.querySelectorAll(
            '.cassie-child-cookie--toggle-switch--slider'
          )
          allToggles.forEach((toggle) => {
            toggle.classList.add('cassie-toggle-switch--slider--active')
          })
        }
      }
      function toggleWidgetWithParam() {
        const appConsent = new URLSearchParams(window.location.search).get(
          'app_consent'
        )
        if (appConsent === 'false' || appConsent === 'true') {
          CassieWidgetLoader.Widget.hideModal()
        }
      }
    }
  }
})
})();
`}
    </Script>
  )
}

export default CookieBanner
