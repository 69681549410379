import { useMemo } from 'react'
import {
  LocaleProvider,
  useLocaleContext,
} from '../src/components/LocaleContext'
import {
  PageProvider,
  pickAllPageContextValues,
} from '../src/components/PageContext'

import { track } from '@dfds-frontend/tracking'
import { css } from '@emotion/react'
import i18next from 'i18next'
import { I18nextProvider } from 'react-i18next'
import { AppShellProvider } from '../src/components/AppShellProvider'
import { BusinessUnitProvider } from '../src/components/BusinessUnitContext'
import { CookieConsentProvider } from '../src/components/CookieConsent/context'
import { conditionallyAddCTAButtonDataToPageTrackingData } from '../src/components/EntryLink/LocalizedLink'
import { LocationProvider } from '../src/components/LocationProvider/LocationProvider'
import { FooterNavigation, TopNavigation } from '../src/components/Navigation'
import { SegmentLoader } from '../src/components/Segment'
import { Seo } from '../src/components/Seo'
import { SiteSettingsProvider } from '../src/components/SiteSettings'
import { Ticker } from '../src/components/Ticker'
import { ThemeProvider } from '../src/themes'
import { usePageTrackingData } from '../src/tracking'
import { CookieBanner } from './../src/components/CookieConsent'
import { useNativeComponents } from './useCustomElements'

const OnRouteUpdateActualTrack = ({ pageTrackingData }) => {
  // eslint-disable-next-line no-unused-vars
  const { bookingEngine, businessArea, currentLocale, ...rest } =
    pageTrackingData

  pageTrackingData = conditionallyAddCTAButtonDataToPageTrackingData({
    event: 'vpv',
    bookingEngine: bookingEngine.toLowerCase(),
    businessArea: businessArea.toLowerCase(),
    ...rest,
  })

  track(pageTrackingData)

  return null
}

const OnRouteUpdateTrack = () => {
  const { getPageTrackingData, explicitTracking } = usePageTrackingData()
  const pageTrackingData = getPageTrackingData()
  // if navigating to a page that doesn't exist contentfulId is not set so we do not track it here.
  // It will be tracked explicitly in ErrorComponent instead
  return explicitTracking || !pageTrackingData?.contentfulId ? null : (
    <OnRouteUpdateActualTrack pageTrackingData={pageTrackingData} />
  )
}

const TranslationProvider = ({ children, readSyncResources }) => {
  const { locale } = useLocaleContext()
  const i18n = useMemo(() => {
    const i = i18next.createInstance()
    // we need to download resources sync or we will get a flash during hydration while fetching the locale files
    // we download the resources using a sync ajax request. We are not putting the short strings in pageContext since
    // all resources strings would have to be duplicated to each page meaning 100k+ extra to download while changing page
    const resources = { [locale]: { translation: readSyncResources(locale) } }
    i.init({
      load: 'currentOnly',
      lng: locale,
      fallbackLng: 'en',
      simplifyPluralSuffix: false,
      pluralSeparator: '_',
      resources,
      ns: ['translation'],
      defaultNS: ['translation'],
      preload: false,
      whitelist: false,
      nonExplicitWhiteliest: false,
      nsSeparator: false,
      interpolation: {
        escapeValue: false,
      },
      keySeparator: false,
      lowerCaseLng: false,
      debug: process.env.NODE_ENV !== 'production',
      react: {
        useSuspense: false,
      },
      initImmediate: false,
    })
    return i
  }, [locale, readSyncResources])
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}

const Provider = ({
  element,
  props: { pageContext, data, location },
  readSyncResources,
}) => {
  useNativeComponents()
  const locale = pageContext.locale || 'en'
  const pageValues = pickAllPageContextValues(pageContext)
  const explicitTracking = data?.content?.page?.explicitTracking
  const isLocalServer =
    location.hostname?.includes('local') || location.protocol === 'http:'
  const heroImage = data?.content?.page?.hero?.contentRef?.image?.url
  const { siteSettings, footerCollection, navigationCollection } =
    pageContext.appShellData || data.content || {}

  // No footer and header on account/login and account/reset-password pages which are not localized
  const isLoginOrResetPwdPage =
    typeof window !== 'undefined' &&
    window.location.pathname.includes('account') &&
    (window.location.pathname.includes('login') ||
      window.location.pathname.includes('reset-password'))

  const isPreview =
    typeof window !== 'undefined' &&
    window.location.pathname.includes('preview')

  return (
    <PageProvider value={pageValues}>
      <CookieConsentProvider>
        <ThemeProvider theme={pageContext.theme}>
          <LocaleProvider
            key={locale}
            value={{
              locale,
              localeLower: locale.toLowerCase(),
            }}
          >
            <TranslationProvider readSyncResources={readSyncResources}>
              <LocationProvider value={location}>
                <BusinessUnitProvider value={pageContext.businessUnit}>
                  <SiteSettingsProvider value={siteSettings}>
                    <Seo heroImage={heroImage} />
                    <AppShellProvider
                      key={`app-${locale}`}
                      staticValue={{
                        footerCollection,
                        navigationCollection,
                      }}
                    >
                      <div
                        css={css`
                          display: grid;
                          grid-template-rows: auto 1fr auto;
                          /* ensure min-width is defined and not auto */
                          grid-template-columns: minmax(0, 1fr);
                          min-height: 100vh;
                          height: 100%;

                          header {
                            grid-row: 1;
                          }

                          main {
                            grid-row: 2;
                          }
                        `}
                      >
                        {!isPreview &&
                          navigationCollection &&
                          !isLoginOrResetPwdPage && (
                            <TopNavigation
                              businessUnit={pageContext.businessUnit}
                              locale={locale}
                              slug={pageContext.slug}
                              slugId={pageContext.slugId}
                              localizedRoutes={pageContext.hrefLangs}
                            />
                          )}
                        <main data-entry-id={data?.content?.page?.sys?.id}>
                          <CookieBanner />
                          {!isLocalServer && <SegmentLoader />}
                          <Ticker />
                          {element}
                        </main>
                        {!isPreview &&
                          footerCollection &&
                          !isLoginOrResetPwdPage && (
                            <FooterNavigation
                              businessUnit={pageContext.businessUnit}
                              slugId={pageContext.slugId}
                            />
                          )}
                      </div>
                    </AppShellProvider>
                    {!explicitTracking && <OnRouteUpdateTrack />}
                  </SiteSettingsProvider>
                </BusinessUnitProvider>
              </LocationProvider>
            </TranslationProvider>
          </LocaleProvider>
        </ThemeProvider>
      </CookieConsentProvider>
    </PageProvider>
  )
}

export const wrapPageElementBase = (props, readSyncResources) => {
  return <Provider {...props} readSyncResources={readSyncResources} />
}
