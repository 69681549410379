import React, { createContext } from 'react'
import { isNil } from 'ramda'

const BusinessUnitContext = createContext(undefined)

export const BusinessUnitProvider = ({ children, value: businessUnit }) => {
  const businessUnitAsType =
    convertContentfulBusinessUnitToBusinessUnitType(businessUnit)
  return (
    <BusinessUnitContext.Provider value={businessUnitAsType}>
      {children}
    </BusinessUnitContext.Provider>
  )
}

export const useBusinessUnitContext = () => {
  const context = React.useContext(BusinessUnitContext)
  if (isNil(context)) {
    throw new Error(
      'useBusinessUnitContext must be used within a BusinessUnitProvider',
    )
  }
  return context
}

const convertContentfulBusinessUnitToBusinessUnitType = (businessUnit) => {
  switch (businessUnit) {
    case 'GROUP':
      return BusinessUnitTypes.GROUP
    case 'PASSENGER':
      return BusinessUnitTypes.PASSENGER
    case 'FREIGHT':
      return BusinessUnitTypes.FREIGHT
    case 'LOGISTICS':
      return BusinessUnitTypes.LOGISTICS
    default:
      return BusinessUnitTypes.OTHER
  }
}

export const BusinessUnitTypes = {
  PASSENGER: Symbol('passenger-ferries'),
  FREIGHT: Symbol('freight-shipping'),
  LOGISTICS: Symbol('logistics-solutions'),
  GROUP: Symbol('group'),
  OTHER: Symbol(''),
}
Object.freeze(BusinessUnitTypes)
