import React from 'react'

const LocationContext = React.createContext(null)

export const useLocationProvider = () => React.useContext(LocationContext)

export const LocationProvider = ({ value, children }) => {
  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  )
}
