import {
  getLocaleSwitcherUrls,
  mapBusinessUnit,
  TopNavigation as Navigation,
  useSetLocale,
} from '@dfds-frontend/navigations'
import { useAppShellContext } from '../AppShellProvider'
import { isBookingFlowPage } from './helpers'
import { Login } from '../Login'
import { useEffect, useState } from 'react'
import { sharedContentClientRest } from '../../apollo/client'
import { getAllLocalizedLandingPaths } from '@dfds-frontend/contentful-api-calls'

/**
 * TODO: remove updateRoutesWithFallback function when freight pages are completely
 * migrated to the Shared Content space.
 * For documentation check: https://dfds.visualstudio.com/CET/_workitems/edit/459274
 */
const updateRoutesWithFallback = (routes, fallbackToLanding) => {
  for (const key in fallbackToLanding) {
    if (!routes[key] || routes[key].length === 0) {
      routes[key] = fallbackToLanding[key]
    }
  }
  return routes
}

const TopNavigation = ({
  locale,
  slug,
  businessUnit,
  slugId,
  localizedRoutes,
}) => {
  const { navigationCollection } = useAppShellContext()
  useSetLocale(locale)
  const bu = mapBusinessUnit(businessUnit)
  const forceMobileNavigationView = isBookingFlowPage(slugId, bu)

  let [availableRoutes, setAvailableRoutes] = useState({})
  let [FFLLandingPaths, setFFLLandingPaths] = useState({})

  useEffect(() => {
    if (!slug || !locale) return
    getLocaleSwitcherUrls(sharedContentClientRest, locale, slug)
      .then((results) => setAvailableRoutes(results.urlsWithFallbacks))
      .catch((err) =>
        console.warn(
          `Locale switcher url query for locale: ${locale} and slug: ${slug}, didn't return any results`,
          err,
        ),
      )

    if (businessUnit === 'FREIGHT') {
      getAllLocalizedLandingPaths(
        sharedContentClientRest,
        'freight-ferries-and-logistics',
      )
        .then((results) => setFFLLandingPaths(...results))
        .catch((err) =>
          console.warn(
            `Error fetching Freight Ferries and Logistics landing pages: `,
            err,
          ),
        )
    }
  }, [locale, slug, businessUnit])

  const routes = {
    ...availableRoutes,
    ...localizedRoutes,
  }

  updateRoutesWithFallback(routes, FFLLandingPaths)

  return (
    <Navigation
      navigations={navigationCollection}
      businessUnit={bu}
      forceMobileNavigationView={forceMobileNavigationView}
      locale={locale}
      localizedRoutes={routes}
      slugId={slugId}
    >
      {bu === 'PASSENGER' && <Login />}
    </Navigation>
  )
}

export default TopNavigation
