import { Global, css } from '@emotion/react'
import { theme } from '@dfds-ui/theme'

const DotComStyles = () => {
  return (
    <Global
      styles={css`
        button[aria-label='renew-consent'] {
          border: none;
          z-index: 3100 !important;
        }

        html {
          background-color: ${theme.colors.surface.secondary};
          overflow-y: scroll;

          &.locked {
            overflow-y: hidden;
          }
        }

        body {
          min-width: 320px;
        }

        img {
          max-width: 100%;
        }

        a {
          color: ${theme.colors.text.secondary.primary};
          text-decoration: none;

          &:visited {
            color: ${theme.colors.text.secondary.primary};
          }

          &:hover {
            color: ${theme.colors.text.secondary.primary};
          }

          &:active {
            color: ${theme.colors.text.secondary.primary};
          }
        }

        p {
          line-height: 1.6;
          margin-top: 0;
          margin-bottom: 16px;

          &:first-of-type {
            margin-top: 0;
          }
        }
        ul {
          padding-left: 15px;
          list-style-type: disc;

          li {
            margin-bottom: 15px;
            margin-left: 10px;
          }
        }
        svg {
          fill: inherit;
        }
      `}
    />
  )
}

export default DotComStyles
