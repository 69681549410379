import { Script, StaticQuery, graphql } from 'gatsby'
import { buildUrl, createMicrodataOrganization } from './'
import { getFaviconLinks, getFaviconMetaTags } from './getFavicons'

import Helmet from 'react-helmet'
import { useLocaleContext } from '../LocaleContext'
import { usePageContext } from '../PageContext'
import { useSiteSettingsContext } from '../SiteSettings'

const Seo = ({ heroImage }) => {
  const { locale } = useLocaleContext()
  const countryCode = locale?.split('-')?.[1]?.toUpperCase() ?? 'INT'
  const {
    metaDescriptionSeo = '',
    pageTitle,
    disableIndexing,
    slug,
    meta = [],
    microdata = {},
    hrefLangs = {},
    faqSection,
    businessUnit,
    mergerCanonicalUrl,
  } = usePageContext()
  const { baseUrl, openGraphSettings = {} } = useSiteSettingsContext()
  const canonicalUrl = baseUrl + buildUrl(locale, slug)
  const faqs = faqSection?.faqCollection?.items
  const titleCountryCode =
    businessUnit !== 'PASSENGER' ? ` (${countryCode})` : ''
  const faviconLinks = getFaviconLinks(locale)
  const faviconMetaTags = getFaviconMetaTags(locale)

  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        return (
          <Helmet
            htmlAttributes={{
              lang: locale,
              locale: locale,
            }}
            title={pageTitle}
            titleTemplate={`%s${
              pageTitle?.trim()?.endsWith(`| ${data.site.siteMetadata.title}`)
                ? titleCountryCode
                : ` | ${data.site.siteMetadata.title}` + titleCountryCode
            }`}
            link={[
              ...faviconLinks,
              {
                rel: 'canonical',
                href: mergerCanonicalUrl ? mergerCanonicalUrl : canonicalUrl,
              },
              ...Object.entries(hrefLangs || {}).map(([locale, slug]) => ({
                rel: 'alternate',
                hrefLang: locale === 'en' ? 'x-default' : locale,
                href: `${baseUrl}${buildUrl(locale, slug)}`,
              })),
            ]}
            meta={[
              ...faviconMetaTags,
              {
                ...(disableIndexing && {
                  name: 'robots',
                  content: 'noindex, nofollow',
                }),
              },
              {
                name: 'businessArea',
                content: businessUnit,
              },
              {
                name: 'countryCode',
                content: countryCode,
              },
              {
                name: 'description',
                content: metaDescriptionSeo,
              },
              {
                prefix: 'og: http://ogp.me/ns#',
                property: 'og:site_name',
                content: openGraphSettings.ogSiteName,
              },
              {
                prefix: 'og: http://ogp.me/ns#',
                property: 'og:locale',
                content: locale,
              },
              {
                prefix: 'og: http://ogp.me/ns#',
                property: 'og:url',
                content: canonicalUrl,
              },
              {
                prefix: 'og: http://ogp.me/ns#',
                property: 'og:image',
                content: heroImage || openGraphSettings.ogDefaultImage?.url,
              },
              {
                prefix: 'og: http://ogp.me/ns#',
                property: 'og:image:width',
                content: openGraphSettings.ogDefaultImage?.width,
              },
              {
                prefix: 'og: http://ogp.me/ns#',
                property: 'og:image:height',
                content: openGraphSettings.ogDefaultImage?.height,
              },
              {
                prefix: 'og: http://ogp.me/ns#',
                property: 'og:title',
                content: pageTitle,
              },
              {
                prefix: 'og: http://ogp.me/ns#',
                property: 'og:description',
                content: metaDescriptionSeo,
              },
              {
                prefix: 'og: http://ogp.me/ns#',
                property: 'og:type',
                content: 'website',
              },
              {
                prefix: 'og: http://ogp.me/ns#',
                name: 'twitter:card',
                content: 'summary',
              },
              {
                prefix: 'og: http://ogp.me/ns#',
                name: 'twitter:site',
                content: openGraphSettings.ogTwitterSite,
              },
              {
                prefix: 'og: http://ogp.me/ns#',
                name: 'twitter:url',
                content: canonicalUrl,
              },
              {
                prefix: 'og: http://ogp.me/ns#',
                name: 'twitter:title',
                content: pageTitle,
              },
              {
                prefix: 'og: http://ogp.me/ns#',
                name: 'twitter:description',
                content: metaDescriptionSeo,
              },
              {
                prefix: 'og: http://ogp.me/ns#',
                name: 'twitter:image',
                content: heroImage || openGraphSettings.ogDefaultImage?.url,
              },
              {
                name: 'google-site-verification',
                content: 'fk3gztqtHKkP9d7FN8ze4J5O-aYdxvBJDPS7XS89hPY',
              },
              {
                name: 'apple-mobile-web-app-title',
                content: '"DFDS A/S',
              },
              {
                name: 'application-name',
                content: 'DFDS A/S',
              },
              {
                property: 'fb:app_id',
                content: '384624209754088',
              },
            ].concat(meta)}
          >
            {microdata &&
              Object.entries(microdata).length !== 0 &&
              microdata.constructor === Object && (
                <Script type="application/ld+json">{`${JSON.stringify(
                  createMicrodataOrganization(microdata),
                )}`}</Script>
              )}
            {faqs && (
              <Script type="application/ld+json">
                {`${JSON.stringify({
                  '@context': 'https://schema.org',
                  '@type': 'FAQPage',
                  mainEntity: faqs.map((faq) => ({
                    '@type': 'Question',
                    name: faq.question,
                    acceptedAnswer: {
                      '@type': 'Answer',
                      text: faq.answer.text,
                    },
                  })),
                })}`}
              </Script>
            )}
          </Helmet>
        )
      }}
    />
  )
}

export default Seo

const detailsQuery = graphql`
  query DefaultSeoQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
