import { transform } from './utils'
import availableTravelDates from './availableTravelDates'
import availableInboundDates from './availableInboundDates'
import vehicleTypes from './vehicleTypes'
import validateOfferCode from './validateOfferCode'
import departures from './departures'
import availableRoutes from './availableRoutes'
import { nasdaq, nasdaqById } from './nasdaq'
import nasdaqChartLatest, {
  nasdaqChartHistory,
  nasdaqShareInfo,
} from './nasdaqChart'

const resolvers = {
  Query: {
    vehicleTypes,
    validateOfferCode,
    availableTravelDates,
    availableInboundDates,
    departures,
    availableRoutes,
    nasdaq,
    nasdaqById,
    nasdaqChartLatest,
    nasdaqChartHistory,
    nasdaqShareInfo,
  },
}

export default transform(resolvers)
