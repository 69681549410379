import React, { createContext } from 'react'

const LocaleContext = createContext(undefined)

export const LocaleProvider = ({ children, value: { locale } }) => {
  const [currentLocale, setCurrentLocale] = React.useState({
    locale: locale,
    localeLower: locale.toLowerCase(),
    language: locale.split('-').shift(),
    country: locale.split('-').pop(),
  })

  return (
    <LocaleContext.Provider
      value={{
        locale: currentLocale.locale,
        localeLower: currentLocale.locale.toLowerCase(),
        language: currentLocale.locale.split('-').shift(),
        country: currentLocale.locale.split('-').pop(),
        setCurrentLocale: (newLocale) =>
          setCurrentLocale({
            locale: newLocale,
            localeLower: newLocale.toLowerCase(),
            language: newLocale.split('-').shift(),
            country: newLocale.split('-').pop(),
          }),
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}

export const useLocaleContext = () => {
  const context = React.useContext(LocaleContext)
  if (context === undefined) {
    throw new Error('useLocaleContext must be used within a LocaleProvider')
  }
  return context
}
