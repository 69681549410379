import qs from 'query-string'

const EUROLANDIR_URL = 'https://tools.eurolandir.com/tools/pricefeed/'

const nasdaqChartLatest = async (_, { companycode }) => {
  const data = await (
    await fetch(
      `${EUROLANDIR_URL}intraday.aspx?${qs.stringify({
        companycode,
        format: 'json',
      })}`,
    )
  ).json()
  return data['32926']
}

export const nasdaqChartHistory = async (_, { companycode, interval }) => {
  const data = await (
    await fetch(
      `${EUROLANDIR_URL}history.aspx?${qs.stringify({
        companycode,
        format: 'json',
        period: interval,
        maxpoints: interval,
      })}`,
    )
  ).json()
  return data['32926']
}

export const nasdaqShareInfo = async (_, { companycode }) => {
  const data = await (
    await fetch(
      `${EUROLANDIR_URL}?${qs.stringify({
        companycode,
        format: 'json',
      })}`,
    )
  ).json()
  return data['DFDS (COP)']
}

export default nasdaqChartLatest
