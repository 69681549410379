const availableTravelDates = async (_, args) => {
  const data = await fetch(
    `${process.env.GATSBY_BFF_URL}availableinbounddates`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(args),
    },
  )
  const { inboundDates } = await data.json()
  return { inboundDates }
}

export default availableTravelDates
