import { createContext, useContext } from 'react'
import { isNil, pickAll } from 'ramda'

const PageContext = createContext(undefined)

export const PageProvider = ({ children, value }) => {
  const initialPageContext = {}

  return (
    <PageContext.Provider value={value || initialPageContext}>
      {children}
    </PageContext.Provider>
  )
}

export const usePageContext = () => {
  const context = useContext(PageContext)
  if (isNil(context)) {
    throw new Error('usePageContext must be used within a PageProvider')
  }
  return context
}

export const pickAllPageContextValues = pickAll([
  'slugId',
  'businessUnit',
  'pageTitle',
  'statusCode',
  'pageTagging',
  'metaDescriptionSeo',
  'disableIndexing',
  'slug',
  'meta',
  'microdata',
  'hrefLangs',
  'faqSection',
  'explicitTracking',
  'mergerCanonicalUrl',
])
