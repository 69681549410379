import {
  isLinkForUnifiedWeb,
  useCustomLinkComponent,
} from '@dfds-frontend/navigations'
import { Link } from 'gatsby'
function LinkComponent({ children, href, ...remainingProps }) {
  if (!href?.startsWith('http') && !href?.startsWith('/')) {
    href = `/${href}`
  }

  if (
    href?.startsWith('http') ||
    isLinkForUnifiedWeb(href) ||
    href.endsWith('/about')
  ) {
    return (
      <a href={href || ''} {...remainingProps}>
        {children}
      </a>
    )
  }

  return (
    <Link to={href || ''} {...remainingProps}>
      {children}
    </Link>
  )
}

export function useNativeComponents() {
  useCustomLinkComponent(LinkComponent)
}
