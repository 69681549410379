import {
  FooterNavigation as Navigation,
  mapBusinessUnit,
} from '@dfds-frontend/navigations'
import { useAppShellContext } from '../AppShellProvider'
import { isBookingFlowPage } from './helpers'

const FooterNavigation = ({ businessUnit, slugId }) => {
  const bu = mapBusinessUnit(businessUnit)
  const { footerCollection } = useAppShellContext()

  const footerData = footerCollection.items.find(
    (navigations) => mapBusinessUnit(navigations.businessUnit) === bu,
  )

  return (
    <Navigation
      footerData={footerData}
      useSmallVariant={isBookingFlowPage(slugId, bu)}
    />
  )
}

export default FooterNavigation
