import { ThemeProvider as StyledThemeProvider } from '@emotion/react'
import { themes } from '.'

const ThemeProviderInsert = (props) => {
  if (props.theme) {
    return (
      <StyledThemeProvider theme={themes[props.theme || 'theme-default']}>
        {props.children}
      </StyledThemeProvider>
    )
  } else {
    if (props.fallback) {
      return <>{props.children}</>
    } else {
      return (
        <StyledThemeProvider theme={themes['theme-default']}>
          {props.children}
        </StyledThemeProvider>
      )
    }
  }
}
const ThemeProvider = (props) => {
  return <ThemeProviderInsert {...props}>{props.children}</ThemeProviderInsert>
}

export default ThemeProvider
