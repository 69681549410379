import React, { createContext } from 'react'
import { isNil } from 'ramda'

const SiteSettingsContext = createContext(undefined)

export const SiteSettingsProvider = ({ children, value: siteSettings }) => {
  const initialSiteSettings = {}

  return (
    <SiteSettingsContext.Provider value={siteSettings || initialSiteSettings}>
      {children}
    </SiteSettingsContext.Provider>
  )
}

export const useSiteSettingsContext = () => {
  const context = React.useContext(SiteSettingsContext)
  if (isNil(context)) {
    throw new Error(
      'useSiteSettingsContext must be used within a SiteSettingsProvider',
    )
  }
  return context
}
