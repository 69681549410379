const Colors = {
  $actionlight: '#E8F4FB',
  $actionblue: '#68B2E3',
  $actionmedium: '#C3E0F4',
  // Colours
  $Gb: '#002B45',
  $Ga: '#5087ac',
  $Sk: '#2D2926',
  $Tg: '#4D4E4C',
  $Wh: '#FFFFFF',
  // #region Z Spring
  $Zap: '#AEB444',
  $Zah: '#D1D244',
  $Zam: '#EBEFB3',

  $Zbp: '#BDCA8F',
  $Zbh: '#829B54',
  $Zbm: '#E1E6CB',

  $Zcp: '#639D9D',
  $Zch: '#335A66',
  $Zcm: '#BFD4D4',

  $Zdp: '#B6CAE0',
  $Zdh: '#7A9EC3',
  $Zdm: '#E5EAF0',

  $Zep: '#D6AACB',
  $Zeh: '#BA77AE',
  $Zem: '#EBDBE5',

  $Zfp: '#BD7094',
  $Zfh: '#893A74',
  $Zfm: '#DEBFCD',

  $Zgp: '#EAB35C',
  $Zgh: '#DE8A46',
  $Zgm: '#F2E1C0',

  $Zhp: '#EADC8B',
  $Zhh: '#DEC26B',
  $Zhm: '#FDFADD',
  // #endregion
  // #region A Summer
  $Aap: '#8B6E99',
  $Aah: '#634471',
  $Aam: '#C6B9CD',

  $Abp: '#8DC2E2',
  $Abh: '#4599CB',
  $Abm: '#CDE3F1',

  $Acp: '#6EAAC2',
  $Ach: '#43838E',
  $Acm: '#BFD5E1',

  $Adp: '#73AA79',
  $Adh: '#328049',
  $Adm: '#D4E5BE',

  $Aep: '#F7E9C4',
  $Aeh: '#F5DFB0',
  $Aem: '#FAF4E5',

  $Afp: '#E8D834',
  $Afh: '#CAB02A',
  $Afm: '#F5EFAE',

  $Agp: '#E6A630',
  $Agh: '#D37232',
  $Agm: '#F8E3B5',

  $Ahp: '#DA807C',
  $Ahh: '#AC4648',
  $Ahm: '#EECAC9',
  // #endregion
  // #region B Autumn
  $Bap: '#B9B67A',
  $Bah: '#918D4F',
  $Bam: '#E3E2CA',

  $Bbp: '#957966',
  $Bbh: '#694C3B',
  $Bbm: '#D4C9C2',

  $Bcp: '#757E86',
  $Bch: '#4C545C',
  $Bcm: '#C8CBCF',

  $Bdp: '#94ABBB',
  $Bdh: '#70808E',
  $Bdm: '#D4DCE4',

  $Bep: '#DA7D65',
  $Beh: '#BD3CE0',
  $Bem: '#F0CBC1',

  $Bfp: '#926167',
  $Bfh: '#613139',
  $Bfm: '#D3C0C2',

  $Bgp: '#E0CD5C',
  $Bgh: '#D1B453',
  $Bgm: '#EFE6B7',

  $Bhp: '#D89B37',
  $Bhh: '#ECB943',
  $Bhm: '#F8E3B4',
  // #endregion
  // #region C Winter
  $Cap: '#C5CBB7',
  $Cah: '#9EA89A',
  $Cam: '#E8EAE2',

  $Cbp: '#839A98',
  $Cbh: '#557670',
  $Cbm: '#CCD6D5',

  $Ccp: '#AE668B',
  $Cch: '#792A5A',
  $Ccm: '#DFC2D1',

  $Cdp: '#DECA6B',
  $Cdh: '#D8B25C',
  $Cdm: '#F2EAC4',

  $Cep: '#CF9C67',
  $Ceh: '#B77C4D',
  $Cem: '#E8D2B7',

  $Cfp: '#616853',
  $Cfh: '#2E382F',
  $Cfm: '#C0C2B7',

  $Cgp: '#785B6F',
  $Cgh: '#321A35',
  $Cgm: '#C9BEC5',

  $Chp: '#C2626B',
  $Chh: '#8B2C38',
  $Chm: '#E7C0C4',
  // #endregion
}

const themes = {
  // #region
  'theme-default': {
    'color-shafts': Colors.$Gb,
    'color-background': Colors.$Gb,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Wh,
    'icon-fill': Colors.$Ga,
    'icon-color': Colors.$Gb,
  },
  'theme-black': {
    'color-shafts': Colors.$Sk,
    'color-background': Colors.$Sk,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Wh,
    'icon-fill': Colors.$Tg,
    'icon-color': Colors.$Sk,
  },
  'theme-home-category': {
    'color-shafts': Colors.$Gb,
    'color-background': Colors.$Gb,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Wh,
    'icon-fill': Colors.$Gb,
    'icon-color': Colors.$actionblue,
  },
  'theme-ferry-crossings': {
    'color-shafts': Colors.$actionlight,
    'color-background': Colors.$actionlight,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$actionblue,
    'icon-color': Colors.$Gb,
  },
  'theme-minicruise-and-packages': {
    'color-shafts': Colors.$actionmedium,
    'color-background': Colors.$actionmedium,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$actionblue,
    'icon-color': Colors.$Gb,
  },
  // #endregion
  // #region Winter
  'winter-Ca': {
    'color-shafts': Colors.$Cam,
    'color-background': Colors.$Cam,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Cah,
    'icon-color': Colors.$Cap,
  },
  'winter-Cb': {
    'color-shafts': Colors.$Cbm,
    'color-background': Colors.$Cbm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Cbh,
    'icon-color': Colors.$Cbp,
  },
  'winter-Cc': {
    'color-shafts': Colors.$Ccm,
    'color-background': Colors.$Ccm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Cch,
    'icon-color': Colors.$Ccp,
  },
  'winter-Cd': {
    'color-shafts': Colors.$Cdm,
    'color-background': Colors.$Cdm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Cdh,
    'icon-color': Colors.$Cdp,
  },
  'winter-Ce': {
    'color-shafts': Colors.$Cem,
    'color-background': Colors.$Cem,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Ceh,
    'icon-color': Colors.$Cep,
  },
  'winter-Cf': {
    'color-shafts': Colors.$Cfm,
    'color-background': Colors.$Cfm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Cfh,
    'icon-color': Colors.$Cfp,
  },
  'winter-Cg': {
    'color-shafts': Colors.$Cgm,
    'color-background': Colors.$Cgm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Cgh,
    'icon-color': Colors.$Cgp,
  },
  'winter-Ch': {
    'color-shafts': Colors.$Chm,
    'color-background': Colors.$Chm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Chh,
    'icon-color': Colors.$Chp,
  },
  // #endregion
  // #region Fall
  'fall-Ba': {
    'color-shafts': Colors.$Bam,
    'color-background': Colors.$Bam,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Bah,
    'icon-color': Colors.$Bap,
  },
  'fall-Bb': {
    'color-shafts': Colors.$Bbm,
    'color-background': Colors.$Bbm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Bbh,
    'icon-color': Colors.$Bbp,
  },
  'fall-Bc': {
    'color-shafts': Colors.$Bcm,
    'color-background': Colors.$Bcm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Bch,
    'icon-color': Colors.$Bcp,
  },
  'fall-Bd': {
    'color-shafts': Colors.$Bdm,
    'color-background': Colors.$Bdm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Bdh,
    'icon-color': Colors.$Bdp,
  },
  'fall-Be': {
    'color-shafts': Colors.$Bem,
    'color-background': Colors.$Bem,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Beh,
    'icon-color': Colors.$Bep,
  },
  'fall-Bf': {
    'color-shafts': Colors.$Bfm,
    'color-background': Colors.$Bfm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Bfh,
    'icon-color': Colors.$Bfp,
  },
  'fall-Bg': {
    'color-shafts': Colors.$Bgm,
    'color-background': Colors.$Bgm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Bgh,
    'icon-color': Colors.$Bgp,
  },
  'fall-Bh': {
    'color-shafts': Colors.$Bhm,
    'color-background': Colors.$Bhm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Bhh,
    'icon-color': Colors.$Bhp,
  },
  // #endregion
  // #region Summer
  'summer-Aa': {
    'color-shafts': Colors.$Aam,
    'color-background': Colors.$Aam,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Aah,
    'icon-color': Colors.$Aap,
  },
  'summer-Ab': {
    'color-shafts': Colors.$Abm,
    'color-background': Colors.$Abm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Abh,
    'icon-color': Colors.$Abp,
  },
  'summer-Ac': {
    'color-shafts': Colors.$Acm,
    'color-background': Colors.$Acm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Ach,
    'icon-color': Colors.$Acp,
  },
  'summer-Ad': {
    'color-shafts': Colors.$Adm,
    'color-background': Colors.$Adm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Adh,
    'icon-color': Colors.$Adp,
  },
  'summer-Ae': {
    'color-shafts': Colors.$Aem,
    'color-background': Colors.$Aem,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Aeh,
    'icon-color': Colors.$Aep,
  },
  'summer-Af': {
    'color-shafts': Colors.$Afm,
    'color-background': Colors.$Afm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Afh,
    'icon-color': Colors.$Afp,
  },
  'summer-Ag': {
    'color-shafts': Colors.$Agm,
    'color-background': Colors.$Agm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Agh,
    'icon-color': Colors.$Agp,
  },
  'summer-Ah': {
    'color-shafts': Colors.$Ahm,
    'color-background': Colors.$Ahm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Ahh,
    'icon-color': Colors.$Ahp,
  },
  // #endregion
  // #region Spring
  'spring-Za': {
    'color-shafts': Colors.$Zam,
    'color-background': Colors.$Zam,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Zah,
    'icon-color': Colors.$Zap,
  },
  'spring-Zb': {
    'color-shafts': Colors.$Zbm,
    'color-background': Colors.$Zbm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Zbh,
    'icon-color': Colors.$Zbp,
  },
  'spring-Zc': {
    'color-shafts': Colors.$Zcm,
    'color-background': Colors.$Zcm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Zch,
    'icon-color': Colors.$Zcp,
  },
  'spring-Zd': {
    'color-shafts': Colors.$Zdm,
    'color-background': Colors.$Zdm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Zdh,
    'icon-color': Colors.$Zdp,
  },
  'spring-Ze': {
    'color-shafts': Colors.$Zem,
    'color-background': Colors.$Zem,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Zeh,
    'icon-color': Colors.$Zep,
  },
  'spring-Zf': {
    'color-shafts': Colors.$Zfm,
    'color-background': Colors.$Zfm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Zfh,
    'icon-color': Colors.$Zfp,
  },
  'spring-Zg': {
    'color-shafts': Colors.$Zgm,
    'color-background': Colors.$Zgm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Zgh,
    'icon-color': Colors.$Zgp,
  },
  'spring-Zh': {
    'color-shafts': Colors.$Zhm,
    'color-background': Colors.$Zhm,
    'color-background-desktop': 'transparent',
    'color-text': Colors.$Gb,
    'icon-fill': Colors.$Zhh,
    'icon-color': Colors.$Zhp,
  },
  // #endregion
}

export default themes
